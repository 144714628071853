<template>
  <div class="goods-item-wrap p-l-10 p-r-10 p-b-10">
    <div
      class="goods-item-card flex flex-main-between"
      :class="soldout ? 'soldout' : ''"
      :data-numiid="numIid"
      :data-miaosha-id="miaoshaId"
      :data-itemurl="itemUrl"
      :data-miaosha-type="miaoshaType"
    >
      <div
        class="img-wrap"
        style="
          width: 12rem;
          height: 12rem;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 5px;
        "
      >
        <img
          :src="pic"
          class="goods-img"
          style="width: 100%; border-raidus: 5px; display: block"
        />
      </div>
      <div class="goods-info" style="">
        <div class="part-1">
          <!--标题-->
          <div class="goods-title">{{ title }}</div>
          <div
            :class="this.soldout ? 'hidden' : ''"
            class="progress-bar-wrap m-t-0 flex flex-cross-center"
            v-if="moment().isAfter(currentDuration.sale_time)"
          >
            <div
              class="progress-bar"
              :data-complete="soldPer"
              data-total="100"
            >
            <div class="progress-complete" :style="progressCompleteWidth"></div>
            </div>
            <div class="m-l-5 fs-10 progress-number">
              <span :class="soldout ? 'hidden' : ''" >&nbsp;已抢{{ soldPer }}%</span>
            </div>
          </div>
          <div class="goods-remark" v-else>
            立省{{
              parseInt(
                Number(couponAmount) +
                  Number(tkPriceUser) +
                  Number(taolijinAmount)
              )
            }}元 | {{ moment(currentDuration.sale_time).format("HH:00") }}开抢
          </div>
        </div>
        <div class="part-2">
          <!--价格-->
          <div class="goods-price p-rel">
            <div class="price-after-cut">
              ￥<span class="price-big-number">{{ priceMiaosha }}</span>
            </div>
            <span class="link-bubble block">原价{{ zkFinalPrice }}元</span>
          </div>
          <!--限量数额-->
          <div class="goods-amount">限量{{ maxCountView }}件</div>
        </div>
      </div>
      <div
        class="p-abs card-btn-wrap"
        style=""
        v-if="moment().isAfter(currentDuration.sale_time)"
      >
        <button
          href="#"
          :class="this.soldout ? 'card-button-soldout' : 'card-button-qiang'"
          class="block open-goods-detail card-btn"
          :data-numiid="numIid"
        >
          {{ soldout ? "已抢光" : "马上抢" }}
        </button>
      </div>
      <div class="p-abs card-btn-wrap" style="" v-else>
        <button
          href="#"
          :class="isYuyue ? 'toast-isyuyue disabled' : 'check-alarm'"
          class="block card-btn card-button-alarm"
          :data-numiid="numIid"
          :data-miaosha-type="miaoshaType"
          :data-miaoshaid="miaoshaId"
        >
          {{ isYuyue == 1 ? "已预约" : "开抢提醒" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
// const moment = $moment
import moment from "moment";
export default {
  name: "GoodCard",
  props: {
    isSoldOut: Number,
    numIid: String,
    miaoshaId: Number,
    itemUrl: String,
    miaoshaType: Number,
    pic: String,
    title: String,
    zkFinalPriceMiaosha: Number,
    zkFinalPrice: Number,
    maxCountView: Number,
    couponAmount: Number,
    tkPriceUser: Number,
    taolijinAmount: Number,
    isYuyue: Number,
    soldPer: Number,
    isvalid: Number,
    activeDuration: {},
  },
  data() {
    return {
      moment: this.$moment,
      soldout: this.isSoldOut,
      currentDuration: {},
    };
  },
  computed:{
    priceMiaosha() {
      return this.zkFinalPriceMiaosha < 0 ? 0 : this.zkFinalPriceMiaosha;
    },
    progressCompleteWidth(){
      var total = 100
      var complete = this.soldPer
      var percent = Number(complete / total * 100).toFixed(0)
      if (complete >= total) {
        percent = 100
      }
      return 'width: ' + percent + '%'
    }
  },
  watch: {
    activeDuration() {
      this.currentDuration = this.activeDuration;
    },
  },
  created() {
    // this.moment = this.$moment
    this.currentDuration = this.activeDuration
    // console.log("this.activeDuration",this.activeDuration)
    // console.log("this.currentDuration",this.currentDuration)
    
    // 如果isvalid为0则认为已抢光
    if (this.isvalid == 0 && moment().isAfter(this.currentDuration.sale_time)) {
      this.soldout = 1;
    }

    // 未开始秒杀的不认为已强光
    if (moment().isBefore(this.currentDuration.sale_time)) {
      this.soldout = 0;
    }

    // 已开始抢，但不超过x秒时显示为可抢
    var randomSeconds = Math.ceil(Math.random() * 5) + 17;
    
    var soldoutButClickable =
      this.soldout &&
      moment().isAfter(this.currentDuration.sale_time) &&
      moment().valueOf() - this.currentDuration.sale_time <
        randomSeconds * 1000;
    if (soldoutButClickable) {
      this.soldout = 0;
    }
  },
  methods: {},
};
</script>
<style lang="css" scoped>
/* 商品卡片部分 */
.goods-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: rgba(74, 74, 74, 1);
  line-height: 2.4rem;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.title-prefix-icon {
  height: 1.5rem;
  margin-right: 5px;
  transform: translateY(2px);
}

.goods-item-card {
  padding: 1rem;
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 5px 0 #ccc;
  margin-bottom: 1.5rem;
}

/* 商品标题和价格信息 */
.goods-info {
  width: calc(100% - 12rem - 1.5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.goods-remark {
  color: #813b13;
  background-color: #ffd569;
  font-size: 1.2rem;
  line-height: 1.2rem;
  padding: 0.2rem 0.5rem;
  display: inline-block;
  font-family: PingFangSC-Regular, PingFang SC;
  margin: 0.5rem 0 0 0;
  border-radius: 2px;
}

.goods-price {
  color: rgba(255, 76, 97, 1);
}

.price-after-cut {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.3rem;
  display: inline-block;
}

.price-after-cut .price-big-number {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1.3rem;
}

.goods-amount {
  font-size: 1.2rem;
  font-weight: 400;
  color: rgba(255, 76, 97, 1);
  line-height: 1.2rem;
  margin-top: 0.5rem;
}

/* 卡片按钮 */
.card-btn-wrap {
  right: 1.5rem;
  bottom: 1.8rem;
  z-index: 10;
}

.card-btn-wrap .card-btn {
  /* width: 8.1rem; */
  outline: none;
  height: 2.8rem;
  line-height: 2.8rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border-radius: 1.4rem;
  border: none;
  padding: 0 10px;
}

/* 马上抢按钮 */
.card-btn-wrap .card-btn.card-button-qiang {
  background: linear-gradient(
    180deg,
    rgba(255, 122, 138, 1) 0%,
    rgba(255, 55, 78, 1) 100%
  );
  color: white;
}

/* 已强光按钮 */
.card-btn-wrap .card-btn.card-button-soldout {
  background: none;
  border: 1px solid rgba(196, 196, 196, 1);
  color: rgba(196, 196, 196, 1);
}

/* 开抢提醒按钮 */
.card-btn.card-button-alarm {
  background: none;
  border: 1px solid #ff4c61;
  color: #ff4c61;
}

.card-btn.card-button-alarm.disabled {
  border-color: #9b9b9b;
  color: #9b9b9b;
}

/* 商品卡片上的价格气泡 */
.link-bubble {
  display: inline-block;
  position: relative;
  top: -1.5rem;
  background-color: #ffeaea;
  line-height: 1.5rem;
  height: 1.4rem;
  font-size: 1rem;
  font-weight: 600;
  color: #ff4c61;
  text-align: center;
  border-radius: 0.3rem;
  border-bottom-left-radius: 0;
  padding: 0 0.5rem;
  min-width: 4em;
  white-space: nowrap;
  z-index: 1;
}

/* .link-bubble::after {
  content: " ";
  display: block;
  position: absolute;
  border-left: 7px solid #FFEAEA;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  bottom: -7px;
  left: 0;
} */

/* 已抢数量进度条 */
.progress-bar {
  width: 10vw;
  height: 0.6rem;
  background-color: #fed0d0;
  border-radius: 0.3rem;
  display: flex;
}

.progress-bar .progress-complete {
  height: 100%;
  background-color: #ff4c61;
  border-radius: 0.3rem;
}

.progress-number {
  height: 1.8rem;
  color: #ff4c61;
}

/* 已强光 */
.goods-item-card.soldout .goods-amount,
.goods-item-card.soldout .link-bubble,
.goods-item-card.soldout .goods-price {
  color: #c4c4c4;
}

.goods-item-card.soldout .link-bubble {
  background-color: #f4f4f4;
}

.goods-item-card.soldout .link-bubble::after {
  border-left-color: #f4f4f4;
}

.goods-item-card.soldout .progress-bar,
.goods-item-card.soldout .progress-bar .progress-complete {
  background-color: #c4c4c4;
}

@media screen and (max-width: 320px) {
  .progress-bar {
    width: 25vw;
  }
}

@-webkit-keyframes pulse-small {
  from {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
}

@keyframes pulse-small {
  from {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
}

.pulse-small {
  -webkit-animation-name: pulse-small;
  animation-name: pulse-small;
}

.animated.animated-18 {
  -webkit-animation-duration: 1.8s;
  animation-duration: 1.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.animated-18.animate-delay-half {
  animation-delay: 0.9s;
}
</style>
